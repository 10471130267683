import styled from 'styled-components'
import { SCREEN } from '@fe/common/constants/main'
import { CardContainer } from './CardContainer'

const Container = styled(CardContainer)`
  width: min-content;
  min-width: 90vw;
  max-width: 100%;

  > svg {
    position: fixed;
    top: 2rem;
    left: 3rem;
    width: 14rem;
    fill: ${({ theme }) => theme.primary};
    z-index: 1;
  }

  ${SCREEN.ABOVE_MOBILE} {
    min-width: 40rem;
  }
`

const Heading = styled.h1`
  margin-bottom: 4rem;
`

interface LoggedOutContainer {
  heading?: string
  className?: string
}

export const LoggedOutContainer: React.FC<LoggedOutContainer> = ({
  heading,
  className,
  children,
}) => (
  <Container className={className}>
    {heading && <Heading>{heading}</Heading>}
    {children}
  </Container>
)
