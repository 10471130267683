import Head from 'next/head'
import { useSelector } from 'react-redux'

import {
  configSelector,
  isCryptoSelector,
} from 'src/state/selectors/globalDataSelectors'

interface LayoutHeadProps {
  title: string
  description?: string
}

export const LayoutHead: React.FC<LayoutHeadProps> = ({
  title,
  description,
}) => {
  const { company_name } = useSelector(configSelector)
  const isCrypto = useSelector(isCryptoSelector)

  const companyName = isCrypto ? 'sminti s.r.o.' : company_name

  return (
    <Head>
      <title>
        {companyName}: {title}
      </title>

      {description && <meta name="Description" content={description} />}
    </Head>
  )
}
