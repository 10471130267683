import Axios, { AxiosPromise } from 'axios'
import { ConfirmationData } from '@fe/common/api/models/ConfirmationData'
import { MfaPreparationData } from '@fe/common/api/models/MfaPreparationData'
import { ConfirmationRequest } from '@fe/common/api/models/RequestModels/ConfirmationRequest'
import { PrepareFirstMfaRequest } from '@fe/common/api/models/RequestModels/PrepareFirstMfaRequest'
import { RegistrationRequest } from '@fe/common/api/models/RequestModels/RegistrationRequest'
import { ResendEmailRequest } from '@fe/common/api/models/RequestModels/ResendEmailRequest'
import { User } from '@fe/common/api/models/User'
import { RequestConfig } from '@fe/common/axiosConfig'

export class RegistrationApi {
  static register(
    regustrationData: RegistrationRequest,
    config?: RequestConfig
  ): AxiosPromise<User> {
    return Axios({
      ...config,
      url: `/users/registration`,
      method: 'POST',
      data: {
        ...regustrationData,
      },
    })
  }

  static prepareMfa(
    mfaData: PrepareFirstMfaRequest,
    config?: RequestConfig
  ): AxiosPromise<MfaPreparationData> {
    return Axios({
      ...config,
      url: `/users/registration/prepare-mfa`,
      method: 'POST',
      data: mfaData,
    })
  }

  static confirmEmail(
    confirmationData: ConfirmationRequest,
    config?: RequestConfig
  ): AxiosPromise<ConfirmationData> {
    return Axios({
      ...config,
      url: `/users/registration/confirm`,
      method: 'POST',
      data: confirmationData,
    })
  }

  static resendEmail(
    data: ResendEmailRequest,
    config?: RequestConfig
  ): AxiosPromise<null> {
    return Axios({
      ...config,
      url: `/users/registration/resend`,
      method: 'POST',
      data,
    })
  }
}
