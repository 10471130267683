import cookie from 'js-cookie'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useState } from 'react'
import styled from 'styled-components'
import { GDPR_HAS_AGREED_COOKIE_KEY } from '@fe/common/constants/cookies'
import { SCREEN, FONT_SIZE, COLOR, BORDER } from '@fe/common/constants/main'
import { setCookie } from '@fe/common/utils/cookies'
import { Button } from './Button'
import { CardContainer } from './CardContainer'

const Container = styled(CardContainer)<{ isDisplayed: boolean }>`
  position: fixed;
  bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  max-width: 90vw;
  padding: 2.5rem;
  border: ${BORDER.DEFAULT};
  font-size: ${FONT_SIZE.SMALL};
  z-index: 1;

  ${SCREEN.ABOVE_MOBILE} {
    flex-direction: row;
  }

  ${SCREEN.ABOVE_TABLET} {
    max-width: 80rem;
    padding: 2rem;
  }

  a {
    color: ${({ theme }) => theme.primary};

    :hover {
      text-decoration: underline;
    }
  }
`

const AcceptButton = styled(Button)`
  height: 4rem;
  margin-top: 2rem;
  background: ${COLOR.WHITE};
  border: 1px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};

  :hover {
    background: ${({ theme }) => theme.primary}11;
  }

  ${SCREEN.ABOVE_MOBILE} {
    margin-top: 0;
    margin-left: 3rem;
  }
`

export const CookieBanner: React.FC = () => {
  const { t } = useTranslation('no-auth')
  const [isDisplayed, setIsDisplayed] = useState(
    !cookie.get(GDPR_HAS_AGREED_COOKIE_KEY)
  )

  const handleAcceptCookies = () => {
    setIsDisplayed(false)
    setCookie(GDPR_HAS_AGREED_COOKIE_KEY, String(true), 10000)
  }

  if (!isDisplayed) {
    return null
  }

  return (
    <Container isDisplayed={isDisplayed}>
      <span>
        {t('cookie-message')}

        <Link href="/docs/terms.pdf" passHref>
          <a rel="noopener nonrefferer" target="_blank">
            {t('common:more-info')}
          </a>
        </Link>
      </span>

      <AcceptButton onClick={handleAcceptCookies} testId="cookies">
        Ok
      </AcceptButton>
    </Container>
  )
}
