import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import styled from 'styled-components'
import { CustomSelect } from '@fe/common/components/inputs/CustomSelect'
import { handleDropdownChange } from '@fe/common/components/inputs/Dropdown'
import { useDidUpdate } from '@fe/common/hooks/useDidUpdate'
import { setCookie } from '@fe/common/utils/cookies'
import { FONT_SIZE, FONT_WEIGHT } from '../constants/main'

const StyledDropdown = styled(CustomSelect)`
  position: absolute !important;
  top: 2rem;
  right: 2rem;
  width: 18rem;
  font-weight: ${FONT_WEIGHT.SEMIBOLD};

  .react-select__control {
    height: 5rem;
    background: #f6f6fa;
    border: none;
  }
`

const LangOption = styled.span`
  display: flex;
  align-items: center;
  line-height: 1;
`

const LangEmoji = styled.span`
  margin-right: 1rem;
  font-size: ${FONT_SIZE.MEDIUM};
`

interface LanguageSelectProps {
  className?: string
}

export const LanguageSelect: React.FC<LanguageSelectProps> = ({
  className,
}) => {
  const {
    i18n: { language },
  } = useTranslation()

  const [lang, setLang] = useState(language)

  const { reload } = useRouter()

  useDidUpdate(() => {
    setCookie('NEXT_LOCALE', lang, 10000)
    reload()
  }, [lang])

  return (
    <StyledDropdown
      value={lang}
      onChange={value => setLang(handleDropdownChange(value) as string)}
      options={[
        {
          label: (
            <LangOption>
              <LangEmoji>🇬🇧</LangEmoji> English
            </LangOption>
          ),
          value: 'en',
        },
        {
          label: (
            <span>
              <LangEmoji>🇨🇿 </LangEmoji> Český
            </span>
          ),
          value: 'cs',
        },
        // {
        //   label: (
        //     <span>
        //       <LangEmoji>🇷🇺</LangEmoji> Русский
        //     </span>
        //   ),
        //   value: 'ru',
        // },
      ]}
      isSearchable={false}
      className={className}
    />
  )
}
