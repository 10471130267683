import styled from 'styled-components'
import { ErrorText } from '@fe/common/components/typography/ErrorText'
import {
  COLOR,
  FONT_SIZE,
  FONT_WEIGHT,
  Z_INDEX,
} from '@fe/common/constants/main'

export const BottomOptions = styled.div`
  position: absolute;
  top: calc(100% + 1.2rem);
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`

export const BottomLink = styled.a`
  position: relative;
  display: block;
  color: ${COLOR.GREY};
  font-size: ${FONT_SIZE.SMALL};
  font-weight: ${FONT_WEIGHT.SEMIBOLD};
  white-space: nowrap;
  z-index: ${Z_INDEX.ABOVE};

  :hover {
    ::after {
      content: '';
      position: absolute;
      top: -0.3rem;
      left: -1rem;
      width: 100%;
      height: 100%;
      padding: 0.2rem 1rem;
      border-radius: 3px;
      background: ${COLOR.LIGHTER_GREY};
      z-index: ${Z_INDEX.BACKGROUND};
    }
  }

  :not(:last-child) {
    margin-bottom: 1rem;
  }
`

export const BackButton = styled.button`
  position: absolute;
  left: calc(50% - 4rem);
  width: 8rem;
  padding: 0.7rem 0;
  color: ${COLOR.GREY};

  :hover {
    color: ${COLOR.OFF_BLACK};
  }
`

export const LoginForm = styled.form`
  button:last-child,
  button:nth-last-child(2) {
    margin-top: 2rem;
  }
`

export const BottomErrorText = styled(ErrorText)`
  position: absolute;
  left: 0;
  bottom: 1.1rem;
  padding: 0 3rem;
`

export const MessageContainer = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height};
  text-align: center;

  svg {
    width: 6rem;
    margin-bottom: 2rem;
    color: ${COLOR.GREEN};
  }
`
