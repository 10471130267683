import Link from 'next/link'
import styled from 'styled-components'
import { CookieBanner } from '@fe/common/components/CookieBanner'
import { LanguageSelect } from '@fe/common/components/LanguageSelect'
import { COLOR, PADDING } from '@fe/common/constants/main'
import Logo from '../../../public/images/logo.svg'
import { Footer } from './Footer'
import { LayoutHead } from './LayoutHead'
import { LOGIN } from 'src/constants/routes'

const Container = styled.main`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  padding: 10rem ${PADDING.MOBILE}rem 15rem;
  background: ${COLOR.BACKGROUND};
`

const LogoIcon = styled(Logo)`
  position: absolute;
  top: 2rem;
  left: 3rem;
  width: 14rem;
  fill: ${({ theme }) => theme.primary};
  z-index: 1;
`

const StyledLanguageSelect = styled(LanguageSelect)`
  position: absolute;
  right: 2rem;
  top: 2rem;
`

const StyledFooter = styled(Footer)`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
`

interface LoggedOutLayoutProps {
  title: string
  description?: string
}

export const LoggedOutLayout: React.FC<LoggedOutLayoutProps> = ({
  title,
  description,
  children,
}) => (
  <Container>
    <LayoutHead title={title} description={description} />

    <Link href={LOGIN} passHref>
      <a>
        <LogoIcon data-test-id="logo_icon" />
      </a>
    </Link>

    <StyledLanguageSelect />

    {children}
    <CookieBanner />

    <StyledFooter />
  </Container>
)
