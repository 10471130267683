import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { domainLanding } from '@fe/common/constants/env'
import { COLOR, PADDING, SCREEN } from '@fe/common/constants/main'
import {
  configSelector,
  isCryptoSelector,
} from 'src/state/selectors/globalDataSelectors'

const Container = styled.footer`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  height: 8rem;
  padding: 0 ${PADDING.MOBILE}rem;

  ${SCREEN.ABOVE_MOBILE} {
    padding: 0 ${PADDING.DESKTOP}rem;
  }
`

const FooterLink = styled.a`
  color: ${COLOR.GREY};

  :hover {
    color: ${COLOR.BLACK};
  }

  :not(:last-child) {
    margin-right: 2rem;
  }

  ${SCREEN.ABOVE_MOBILE} {
    :not(:last-child) {
      margin-right: 5rem;
    }
  }
`

interface FooterProps {
  className?: string
}

export const Footer: React.FC<FooterProps> = ({ className }) => {
  const { t } = useTranslation()
  const { company_name: company } = useSelector(configSelector)
  const isCrypto = useSelector(isCryptoSelector)

  return (
    <Container className={className}>
      <FooterLink
        data-test-id="domain_landing"
        href={`https://${domainLanding}`}
        rel="noopener nonrefferer"
        target="_blank"
      >
        {company}
      </FooterLink>

      <Link href="/docs/terms.pdf" prefetch={false} passHref>
        <FooterLink
          data-test-id="term_of_use"
          rel="noopener nonrefferer"
          target="_blank"
        >
          {t('common:terms-of-use')}
        </FooterLink>
      </Link>

      {(isCrypto) || (
      <Link href="/docs/framework-agreement.pdf" prefetch={false} passHref>
        <FooterLink
                data-test-id="framework_agreement"
                rel="noopener nonrefferer"
                target="_blank"
        >
                {t('common:framework-agreement')}
        </FooterLink>
      </Link>
      )}

      {(isCrypto) || (
      <Link href="/docs/pricelist.pdf" prefetch={false} passHref>
        <FooterLink
                data-test-id="price_list"
                rel="noopener nonrefferer"
                target="_blank"
        >
                {t('common:price-list')}
        </FooterLink>
      </Link>
      )}

    </Container>
  )
}
